import { FC, createRef, useState } from 'react'
import { DateTime } from 'luxon'
import { BoltIcon } from '@heroicons/react/20/solid'
import { PlusIcon } from '@heroicons/react/24/outline'
import { surveys, useSubscription } from '../../../utils'
import { Button, Dialog, Spinner } from '../..'
import { StyledCancelPlanDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const CancelPlanDialog: FC<Props> = ({ isOpen, onClose }) => {
  const {
    plan,
    actions: { cancelPlan }
  } = useSubscription()
  const [loading, setLoading] = useState(false)
  const dialogRef = createRef<HTMLDivElement>()

  const handleClose = () => {
    const dialog = dialogRef.current as any

    if (!dialog) {
      return onClose()
    }

    dialog.close()
  }

  const handleCancel = async () => {
    const w = window as any

    setLoading(true)
    await cancelPlan()
    // w._refiner('showForm', surveys.cancellationSurvey)
    setLoading(false)
    onClose()
  }

  const getFormattedDate = (format: 'full' | 'relative') => {
    if (!plan?.endDate) {
      return ''
    }

    const endDate = DateTime.fromSeconds(plan.endDate)

    if (format === 'full') {
      return endDate.toLocaleString(DateTime.DATE_MED)
    }

    const daysLeft = endDate.toRelative()?.replace('in', '').trimStart()

    return daysLeft
  }

  if (!plan?.endDate) {
    return null
  }

  return (
    <Dialog hasXIcon ref={dialogRef} isOpen={isOpen} onClose={() => onClose()}>
      <StyledCancelPlanDialog>
        <div className="dialog-header">
          <h1>We're sad to see you go</h1>
          <p>
            You have <b>{getFormattedDate('relative')} left</b> on your subscription.
            <br />
            On <b>{getFormattedDate('full')}</b> you'll lose access to...
          </p>
        </div>
        <div className="dialog-content">
          <ul className="pro-bullets">
            <li>
              <BoltIcon />
              <span>Creating unlimited social posts using the new and improved Post Editor</span>
            </li>
            <li>
              <BoltIcon />
              <span>Auto-posting to your Instagram, Facebook, Twitter & LinkedIn accounts</span>
            </li>
            <li>
              <BoltIcon />
              <span>
                Our ever growing library of 8000+ social media captions, photos, and stories
              </span>
            </li>
            <li>
              <BoltIcon />
              <span>
                Engaging with an active Community of thousands of like-minded business owners
              </span>
            </li>
            <li>
              <BoltIcon />
              <span>
                Step-by-step educational Action Plans with the latest marketing strategies
              </span>
            </li>
            <li>
              <PlusIcon /> <span>All your posts, captions, photos & more</span>
            </li>
          </ul>
        </div>
        <div className="dialog-actions">
          <Button className="back-btn" disabled={loading} onClick={() => handleClose()}>
            Stay on the Pro Plan
          </Button>
          <Button
            variant="destructive"
            className="continue-btn"
            disabled={loading}
            onClick={() => handleCancel()}
          >
            {loading ? <Spinner /> : 'Cancel Plan'}
          </Button>
        </div>
      </StyledCancelPlanDialog>
    </Dialog>
  )
}

export default CancelPlanDialog
