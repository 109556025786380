import { useLocation } from 'react-router-dom'
import { SegmentAnalytics } from 'capacitor-segment-plugin'
import { Capacitor } from '@capacitor/core'
import { useAppSelector } from '../../redux'
import { UserType } from '../../types'
import { getActiveSplits, objectToSnake } from '.'

export const useSegment = () => {
  const w = window as any
  const location = useLocation()
  const native = Capacitor.isNativePlatform()
  const { splits } = useAppSelector((state) => state.splits)

  const initialize = async () => {
    try {
      const writeKey = process.env.REACT_APP_SEGMENT_KEY

      if (!writeKey) {
        return
      }

      await SegmentAnalytics.initialize({
        writeKey,
        trackLifecycleEvents: true,
        recordScreenViews: false
      })
    } catch (err) {}
  }

  const identify = async (user: UserType) => {
    try {
      if (!user) {
        return
      }

      const fullName = user.displayName || ''
      const firstAndLast = fullName.split(' ')

      const customerData = {
        name: user.displayName,
        firstName: firstAndLast[0] || '',
        lastName: firstAndLast.slice(1).join(' ') || '',
        email: user.email,
        trial: user?.trial,
        has_trialed: user?.hasTrialed,
        facebook_url: user.facebookURL,
        instagram_url: user.instagramURL,
        free: user.free || false,
        stripe_subscription_status: user.stripeSubscriptionStatus,
        subscription_interval: user.subscriptionInterval,
        stripe_customer_id: user.stripeCustomerId,
        role: user.role,
        phone: user.phone,
        course: user.course,
        beta: user.beta || false,
        use: user?.aiOnboarding?.use || 'Personal',
        occupation: user?.aiOnboarding?.occupation || '',
        company: user?.aiOnboarding?.company || '',
        ages: user?.aiOnboarding?.ages || [],
        genders: user?.aiOnboarding?.genders || [],
        occupations: user?.aiOnboarding?.occupations || [],
        voices: user?.aiOnboarding?.voices || [],
        user_created_at: user.createdAt?.seconds ? new Date(user.createdAt.seconds * 1000) : null,
        connected_profiles_amount: user?.socialProfiles?.length
      }

      if (native) {
        return await SegmentAnalytics.identify({
          userId: user.id,
          traits: customerData
        })
      }

      // w._refiner('identifyUser', {
      //   id: user.id,
      //   ...customerData
      // })

      w.analytics.identify(user?.id, customerData)
    } catch (err) {}
  }

  const track = (eventName: string, properties?: any, options?: any) => {
    try {
      if (native) {
        return SegmentAnalytics.track({
          eventName,
          properties,
          options
        })
      }

      w.analytics.track(
        eventName,
        {
          ...objectToSnake(properties),
          active_splits: getActiveSplits(splits),
          from: location.pathname,
          platform: Capacitor.getPlatform(),
          is_new_platform: true
        },
        options
      )
      w.Intercom('trackEvent', eventName, { ...properties })
    } catch (err) {}
  }

  const page = () => {
    w.analytics.page()
  }

  return { initialize, identify, track, page }
}
