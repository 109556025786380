import { FC, createRef, useContext, useEffect } from 'react'
import firebase from 'firebase/app'
import { useApolloClient } from '@apollo/client'
import { Sidebar } from '../../../components'
import { loginAsUser } from '../../../graphql'
import { useIsDesktop, userStore } from '../../../utils'
import { StyledAcademyView } from '.'

const AcademyView: FC = () => {
  const BASE_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001'
      : 'https://academy.socialcurator.com'
  const apollo = useApolloClient()
  const { isDesktop } = useIsDesktop()
  const { user } = useContext(userStore)

  const handleMessage = async (e: MessageEvent) => {
    if (!e?.data) {
      return
    }

    if (e?.data?.type === 'path_change') {
      console.log(e.data)
      const url = `${window.origin}/academy${e.data.location.pathname}${e.data.location.search}`
      window.document.title = e.data.title
      return window.history.pushState(e.data.title, e.data.title, url)
    }

    if (e?.data !== 'not-authenticated') {
      return
    }

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: loginAsUser
    })

    const redirect = encodeURIComponent(window.location.pathname.replace('/academy', ''))

    const frame = document.getElementById('academy-frame') as HTMLIFrameElement

    if (!frame) {
      return
    }

    frame.src = `${BASE_URL}/connect?token=${data.loginAsUser}&redirect=${redirect}`
  }

  useEffect(() => {
    if (!user?.id) {
      window.location.href = BASE_URL
      return
    }

    window.addEventListener('message', handleMessage, false)

    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [user?.id])

  if (!user?.id) {
    return null
  }

  return (
    <StyledAcademyView>
      {isDesktop && <Sidebar />}
      <iframe
        src={`${BASE_URL}/connect?sso_check=true&redirect=${encodeURIComponent(
          window.location.pathname.replace('/academy', '')
        )}`}
        allow="camera;microphone;display-capture;fullscreen"
        id="academy-frame"
      />
    </StyledAcademyView>
  )
}

export default AcademyView
